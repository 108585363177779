import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './css/additional-styles/utility-patterns.css';
import './css/style.css';
// /* Additional styles */
import './css/additional-styles/flatpickr.css';
import './css/additional-styles/fullcalendar.css';
/* Text Input Styles  */
import './css/TextInput.css';
import 'react-phone-number-input/style.css';
import './css/additional-styles/toastify.css';

import './css/custom.css';
import './charts/ChartjsConfig';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import Intercom from '@intercom/messenger-js-sdk';
import { useDispatch, useSelector } from 'react-redux';
import RefreshModal from 'components/RefreshModal';
import { APP_CURRENT_VERSION } from 'utils/AppVersion';
import {
  setAccessType,
  setLocalUser,
  setPermissions,
  setUserLoading,
} from './redux/User/reducer';
import {
  DevEnv,
  IS_PERSON,
  isPathAllowed,
  OWNER,
  ProdEnv,
  STAFF,
  TEAM_CURRENCY,
  TEAM_DATE_FORMAT,
  TEAM_ID,
  TEAM_NAME,
} from './utils/Utils';
import {
  getAppVersion,
  getCurrenciesData,
  getPeopleTeam,
  getPermissions,
  getTeamById,
  getTeamSubscriptions,
  getUser,
} from './API/backend_helper';
import Route from './routes';
import { setSubscription } from './redux/Subscription/reducer';
import { setTeam } from './redux/Team/reducer';
import { setCurrencies } from './redux/Currencies/reducer';
// Allowed pages that user can visit in expiration mode
const AllowedExpiredSub = [
  '/logout',
  '/workspace',
  '/manage-workspaces',
  '/manage-workspace',
  '/account-details',
  '/privacy-policy',
  '/privacy',
  '/terms',
  '/invoice-payment/:invoice',
  '/public/invoice-estimate/:invoice',
];

// To fix IOS mobile input zoom in issue
const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    const re = /maximum\-scale=[0-9\.]+/g;

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};

const Env = process.env.REACT_APP_ENV;

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessType, localUser } = useSelector((state) => state.User);
  const { subscription } = useSelector((state) => state.Subscription);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [refreshModalOpen, setRefreshModalOpen] = useState(false);

  const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
  const checkIsIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const accessToken = localStorage.getItem('accessToken');
  const teamId = localStorage.getItem(TEAM_ID) || '';
  const teamName = localStorage.getItem(TEAM_NAME);
  const isPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;
  const location = useLocation();

  if (localUser?.id) {
    if (!localUser?.doNotTrack) {
      if (Env === ProdEnv) {
        LogRocket.init('vsonsd/count-prod');
      } else {
        LogRocket.init('vsonsd/count-app-dev');
      }
      setupLogRocketReact(LogRocket);
    }
  } else {
    if (Env === ProdEnv) {
      LogRocket.init('vsonsd/count-prod');
    } else {
      LogRocket.init('vsonsd/count-app-dev');
    }
    setupLogRocketReact(LogRocket);
  }

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]); // triggered on route change

  const getTeamApi = async () => {
    if (!teamId) {
      return;
    }
    try {
      let res = {};
      if (isPerson) {
        res = await getPeopleTeam();
      } else {
        res = await getTeamById(teamId);
      }
      if (res?.id) {
        localStorage.setItem(TEAM_CURRENCY, res?.currency);
        localStorage.setItem(TEAM_DATE_FORMAT, res?.defaults?.dateFormat);
        dispatch(setTeam(res));
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadCurrencies = async () => {
    try {
      const res = await getCurrenciesData();
      dispatch(setCurrencies(res));
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadPermissions = async () => {
    try {
      const res = await getPermissions();
      dispatch(setPermissions(res?.data));
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadAppVersion = async () => {
    try {
      const res = await getAppVersion();
      if (APP_CURRENT_VERSION !== res?.data?.version) {
        setRefreshModalOpen(true);
      } else {
        setRefreshModalOpen(false);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    loadAppVersion();
    const interval = setInterval(
      () => {
        loadAppVersion();
      },
      60 * 60 * 1000, // Call every 1 hour
    );
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (teamId) {
      getTeamApi();
      loadPermissions();
    }
  }, [teamId]);

  useEffect(() => {
    loadCurrencies();
    if (localStorage.getItem('sidebar-expanded') === 'true') {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, []);

  const getUserApi = async (token, id) => {
    try {
      dispatch(setUserLoading(true));
      const response = await getUser(token, id);
      if (response) {
        dispatch(setLocalUser(response));
      }
      if (response?.realm !== STAFF) {
        if (response?.teams?.length > 0) {
          response?.teams?.forEach((team) => {
            if (+teamId === team?.id) {
              // setAccessType(team?.credentials?.roles?.[0]);
              dispatch(setAccessType(team?.credentials?.roles?.[0]));
            }
          });
        }
      } else {
        dispatch(setAccessType(OWNER));
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      dispatch(setUserLoading(false));
    }
  };

  useEffect(() => {
    if (checkIsIOS()) {
      disableIosTextFieldZoom();
    }
  }, []);

  useEffect(() => {
    if (accessToken && (teamId || !localUser?.id)) {
      getUserApi(accessToken, teamId);
    }
  }, [accessToken, teamId]);

  const loadSubscriptions = async () => {
    try {
      const res = await getTeamSubscriptions(teamId);
      dispatch(setSubscription(res?.data?.subscription));
      if (
        (!res?.data?.subscription?.status ||
          res?.data?.subscription?.status === 'expired') &&
        parseInt(teamId) === team?.id
      ) {
        if (
          !isPathAllowed(location.pathname, AllowedExpiredSub) &&
          !team.isOnComplimentary
        ) {
          navigate('/manage-workspace/billing');
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (location.pathname) {
      if (
        typeof subscription !== 'string' &&
        (!subscription?.status || subscription?.status === 'expired') &&
        parseInt(teamId) === team?.id
      ) {
        if (
          !isPathAllowed(location.pathname, AllowedExpiredSub) &&
          !team.isOnComplimentary
        ) {
          navigate('/manage-workspace/billing');
        }
      }
    }
  }, [location.pathname, subscription]);

  useEffect(() => {
    if (localUser?.id && teamId && !isPerson && team?.id) {
      loadSubscriptions();
    }
  }, [localUser, teamId, isPerson, team]);

  useEffect(() => {
    if (localUser?.id && !localUser?.doNotTrack) {
      // if (Env === DevEnv) {
      window?.COUNT?.(
        'param',
        'customer',
        JSON.stringify({
          id: localUser?.id,
          firstName: localUser?.firstName,
          lastName: localUser?.lastName,
          email: localUser?.email,
        }),
      );
      // }
      LogRocket.identify(localUser?.id, {
        name: `${localUser?.firstName} ${localUser?.lastName}`,
        email: localUser?.email,
        userId: localUser?.id,
        teamId: teamId || null,
      });
    }
  }, [localUser, teamId]);

  // useEffect(() => {
  //   try {
  //     if (window?.Tawk_API?.login && localUser?.id && teamId) {
  //       const hash = getHexEncodedHash(
  //         `${localUser?.id}`,
  //         process.env.REACT_APP_TAWK_API_KEY
  //       );
  //       window?.Tawk_API?.login?.({
  //         name: `${localUser?.firstName} ${localUser?.lastName}`,
  //         email: localUser?.email,
  //         hash: hash,
  //         userId: `${localUser?.id}`,
  //         teamId: teamId ? teamId : null,
  //       });
  //     }
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  // }, [window?.Tawk_API?.login, localUser, teamId]);

  // useEffect(() => {
  //   try {
  //     if (window?._hsq && localUser?.id && teamId) {
  //       const _hsq = (window._hsq = window._hsq || []);
  //       _hsq?.push?.([
  //         'identify',
  //         {
  //           name: `${localUser?.firstName} ${localUser?.lastName}`,
  //           email: localUser?.email,
  //           id: `${localUser?.id}`,
  //           teamId: teamId || null,
  //         },
  //       ]);
  //     }
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // }, [window?._hsq, localUser, teamId]);

  useEffect(() => {
    Intercom(
      localUser?.id
        ? {
            app_id: 'bug4m6gg',
            user_id: `${localUser?.id || ''}`,
            name: `${localUser?.firstName || ''} ${localUser?.lastName || ''}`,
            email: localUser?.email || '',
            created_at: localUser?.createdAt || '',
            team_id: teamId || null,
            team_name: teamName || null,
          }
        : {
            app_id: 'bug4m6gg',
          },
    );
    if (localUser?.id && Env === DevEnv && !localUser?.doNotTrack) {
      const gTag = window.gtag || null;
      gTag?.('config', 'G-ZQJ083B18F', {
        user_id: localUser?.id,
      });
    }
    if (localUser?.id && Env === ProdEnv && !localUser?.doNotTrack) {
      const gTag = window.gtag || null;
      gTag?.('config', 'G-NYDCLK9B26', {
        user_id: localUser?.id,
      });

      // <!-- Meta Pixel Code -->
      // eslint-disable-next-line no-unused-expressions
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      );
      fbq?.('init', '726124639610264', {
        em: (localUser?.email && localUser?.email.toLowerCase()) || null,
        fn: (localUser.firstName && localUser.firstName.toLowerCase()) || null,
        ln: (localUser.lastName && localUser.lastName.toLowerCase()) || null,
        ph: localUser.phone || null,
      });
      fbq('track', 'PageView');
    }
  }, [localUser, teamId]);

  return (
    <>
      <RefreshModal isOpen={refreshModalOpen} setIsOpen={setRefreshModalOpen} />
      <Route />
    </>
  );
}

export default App;
