export const REPORTS_DATA = [
  // Financial_Statements
  {
    id: 'profit & loss',
    groupBy: 'Financial_Statements',
    title: 'Profit & Loss Statement',
    description:
      "Shows your business's net profit and summarizes your revenue.",
    tooltipContent: '',
    navigateTo: '/reports/profit-loss',
  },
  {
    id: 'pnl_tags',
    groupBy: 'Financial_Statements',
    title: 'Profit & Loss by Tags',
    description: 'Analyze profitability by Tags e.g. teams or departments.',
    tooltipContent:
      'The Profit & Loss by Tags report offers a side-by-side comparison of profitability across different parts of your business, such as teams, departments, divisions, or profit centers. Tags provide a flexible way to capture transactional data, allowing you to see how each area contributes to overall business performance. This report gives you a clear view of financial health by breaking down revenue and expenses by tagged segments.',
    navigateTo: '/reports/profit-loss-tags',
  },
  {
    id: 'balance sheet',
    groupBy: 'Financial_Statements',
    title: 'Balance Sheet',
    description: 'A snapshot of finances on any given day!',
    tooltipContent:
      'A snapshot of your finances at a given timeframe. The balance sheet calculates your business&acute;s worth (equity) by subtracting what you owe (liabilities) from what you own (assets).',
    navigateTo: '/reports/balance-sheet',
  },
  {
    id: 'category breakdown',
    groupBy: 'Financial_Statements',
    title: 'Category Breakdown',
    description:
      'See how your income and expenses stack up across different categories.',
    tooltipContent: '',
    navigateTo: '/reports/category-breakdown',
  },
  {
    id: 'taxable_non_taxable_income',
    groupBy: 'Financial_Statements',
    title: 'Taxable vs Non-Taxable Income',
    description:
      'Provides a breakdown of taxable and non-taxable sales by income account.',
    tooltipContent:
      "This report details your business's sales, broken down by taxable and non-taxable categories for each income account. Use it to gain insights into how much of your revenue is subject to taxes and identify areas where sales are exempt from taxation.",
    navigateTo: '/reports/income-by-taxabl-non-taxable',
  },

  // Employee_Time_Management
  {
    id: 'time sheets',
    groupBy: 'Employee_Time_Management',
    title: 'Timesheet Overview',
    description:
      'View timesheets across all people with filter and export options for payroll.',
    tooltipContent:
      'Get a comprehensive look at timesheet data, easily filter by employee, project, or service, and group the information to suit your needs. Export ready for payroll integration.',
    navigateTo: '/reports/time-sheets',
  },

  // Customers
  {
    id: 'income by customers',
    groupBy: 'Customers',
    title: 'Income by Customers',
    description:
      'Drill down and understand which customers are driving your revenue.',
    tooltipContent: '',
    navigateTo: '/reports/customer-income',
  },
  {
    id: 'aged receivables',
    groupBy: 'Customers',
    title: 'Aged Receivables',
    description:
      'Identify outstanding invoices and prioritize collections to ensure cash flow.',
    tooltipContent: '',
    navigateTo: '/reports/aged-receivables',
  },
  {
    id: 'invoices by sales rep',
    groupBy: 'Customers',
    title: 'Sales Rep Performance',
    description: 'Breakdown of invoices and revenue by sales reps.',
    tooltipContent:
      "Provides a detailed summary of each sales rep's invoices over a specified period, showing payments received, outstanding payments and total revenue generated. This report helps track the performance and effectiveness of individual sales reps, offering valuable insights into their contributions to the business's income.",
    navigateTo: '/reports/sales-rep-invoices',
  },
  {
    id: 'customer statment',
    groupBy: 'Customers',
    title: 'Customer Statement',
    description:
      'Drill down and understand which customers are driving your revenue.',
    tooltipContent: '',
    navigateTo: '/customer-statement',
  },

  // Accounting
  {
    id: 'account transactions',
    groupBy: 'Accounting',
    title: 'Account Transactions',
    description:
      'This comprehensive report aids in auditing and verifying transactions.',
    tooltipContent:
      'Generate an Account Transactions report to view detailed information on all transactions entered into your COUNT workspace. This comprehensive report aids in auditing and verifying each transaction, ensuring accuracy and transparency across your accounts.',
    navigateTo: '/reports/account-transaction',
  },
  {
    id: 'expense claims',
    groupBy: 'Accounting',
    title: 'Expense Claims',
    description: 'View expense claims by account and reporter.',
    tooltipContent:
      'This report provides a comprehensive view of expense claims, detailing all expenses for a particular account and reporter, along with their approval status. It also includes bank transactions that have not yet been submitted for approval by the reporter. Filter the data by date range, reporter, and account.',
    navigateTo: '/reports/expense-claim',
  },
  {
    id: 'sale tax liability',
    groupBy: 'Accounting',
    title: 'Sales Tax Liability',
    description:
      'Review your sales tax obligations based on collected amounts.',
    tooltipContent:
      'This report provides a detailed breakdown of all sales tax collected from your sales transactions. It helps you understand the total tax owed to tax authorities, making it easier to prepare for remittances. You can filter the report by date range and tax agencies, giving you a clear overview of your outstanding sales tax liabilities and ensuring you stay compliant with tax regulations.',
    navigateTo: '/reports/sales-tax-liability',
  },
];
