import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import DateFilter from 'pages/transactions/DateFilter';
import CategoriesFilter from 'pages/transactions/Filters/CategoriesFilter';

import SearchForm from '../../../partials/actions/SearchForm';
import PaginationClassic from '../../../components/PaginationClassic';
import Tooltip from '../../../components/Tooltip';
import emptySearchImg from '../../../images/custom/empty-search.svg';
import {
  getIncDecAccountTypeStatus,
  getCurrencySymbolFromIso,
  trimText,
  getFormattedDate,
} from '../../../utils/Utils';

const DescriptionLength = 25;
let searchTimeout = null;

const ProjectExpense = ({
  setSelectedRow,
  allCategories,
  selectedAccount,
  setSelectedAccount,
  isReset,
  setIsReset,
  startDateFilter,
  setStartDateFilter,
  endDateFilter,
  setEndDateFilter,
  journalentries,
  isLoading,
  flyoutOpen,
  setFlyoutOpen,
  getData,
  localLimit,
  setLocalLimit,
  searchValue,
  setSearchValue,
}) => {
  const descriptionTDRef = useRef(null);
  const navigate = useNavigate();

  // console.log("description Ref", descriptionTDRef.current?.clientWidth);

  const { currencies } = useSelector((state) => state.Currencies);

  const [activeEntry, setActiveEntry] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState('Expenses');

  const [accountLength, setAccountLength] = useState(20);

  useEffect(() => {
    if (formOpen || flyoutOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [formOpen, flyoutOpen]);

  const onSearch = (val) => {
    setSearchValue(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getData({
        page: 1,
        search: encodeURIComponent(val || ''),
        loading: true,
      });
    }, 900);
  };

  const { page, filters, totalRecords, results } = journalentries || {};

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setAccountLength(40);
      } else if (window.innerWidth > 1700) {
        setAccountLength(35);
      } else if (window.innerWidth > 992) {
        setAccountLength(20);
      } else {
        setAccountLength(20);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onDateChange = async (startDate, endDate) => {
    await getData({
      startDate: startDate ? getFormattedDate(startDate) : '',
      endDate: endDate ? getFormattedDate(endDate) : '',
      page: 1,
      loading: true,
    });
  };

  const handleResetFilters = async () => {
    setSelectedAccount([]);
    setStartDateFilter('');
    setEndDateFilter('');
    setSearchValue('');
    setIsReset(true);
    await getData({
      page: 1,
      accounts: [],
      startDate: '',
      endDate: '',
      search: '',
      loading: true,
    });
  };

  return (
    <main className="relative grow ">
      {/* Content */}
      <div className="sm:px-6 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
        {/* Page header */}

        {!formOpen && (
          <>
            {/* Table */}
            {isLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : (
              <div className="bg-white overflow-auto">
                {/* Table */}
                <table className="table-fixed w-full">
                  {/* Table header */}
                  <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                    <tr className=" w-full py-[1.438rem] px-[1.375rem] h-16">
                      <th className="px-2 first:pl-0 last:pr-5 py-3 whitespace-nowrap w-[7rem] min-w-[7rem]">
                        <div className="text-left font-medium px-4">Date</div>
                      </th>
                      <th className="px-2 pl-4 py-3 whitespace-nowrap min-w-[200px] w-[200px]">
                        <div className="text-left font-medium">Description</div>
                      </th>

                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[12rem] w-[12rem]">
                        <div className="text-left font-medium">Account</div>
                      </th>
                      {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[8%]">
                                <div className="text-left font-medium">
                                  Customer
                                </div>
                              </th> */}
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  min-w-[10rem] w-[10rem]">
                        <div className="text-left  font-medium">Type</div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  min-w-[8rem] w-[8rem]">
                        <div className="text-left  font-medium">Tags</div>
                      </th>
                      {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[9%]">
                                <div className="text-left  font-medium">
                                  Amount
                                </div>
                              </th> */}
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[7rem] w-[7rem]">
                        <div className="text-left  font-medium">Amount</div>
                      </th>
                      {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  min-w-[100px] w-[100px]">
                              <div className="text-left font-medium">
                                Actions
                              </div>
                            </th> */}
                    </tr>
                  </thead>
                  {/* Table body */}
                  {results?.length > 0 && (
                    <tbody className="text-sm">
                      {results?.map((journalEntries, i) => (
                        <>
                          {/* {i !== 0 && <tr className="h-[25px]" />} */}
                          {journalEntries?.data?.map((journalEntry, index) => (
                            <tr
                              key={journalEntry.id}
                              className={`min-w-full px-[1.375rem]  h-[68px]  hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer text-slate-700
                                 ${
                                   journalEntries?.data?.length - 1 === index &&
                                   'border-b border-gray-300'
                                 }
                                  ${
                                    activeEntry?.id === journalEntry?.id
                                      ? 'bg-[#a0cd85] bg-opacity-5'
                                      : ''
                                  }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  journalEntry?.transactionId ||
                                  journalEntry?.billInstanceId ||
                                  journalEntry?.invoiceId
                                ) {
                                  setActiveEntry(journalEntry);
                                  setSelectedRow(journalEntry);

                                  if (journalEntry?.transactionId) {
                                    setFlyoutOpen(true);
                                  } else if (journalEntry?.billInstanceId) {
                                    navigate('/bills', {
                                      state: {
                                        activeBillId:
                                          journalEntry?.billInstanceId,
                                      },
                                    });
                                  } else if (journalEntry?.invoiceId) {
                                    navigate(
                                      `/invoices?tab=invoices&id=${journalEntry?.invoiceId}&from=journalEntry`,
                                    );
                                  }
                                } else if (!journalEntry?.systemCreated) {
                                  navigate('/journal-entries', {
                                    state: {
                                      journalEntry: journalEntries?.data,
                                    },
                                  });

                                  setActiveEntry();
                                }
                              }}
                            >
                              <td className="pl-4 pr-4 text-sm whitespace-nowrap">
                                <div className="">
                                  {moment
                                    ?.utc(journalEntry?.date)
                                    ?.format('MMM DD, YYYY')}
                                </div>
                              </td>
                              <td className="px-2 pl-4 last:pr-5 text-sm py-1 whitespace-nowra">
                                <div
                                  ref={descriptionTDRef}
                                  className="flex items-center descriptionTextTD text-slate-700"
                                >
                                  <div
                                    className={`absolute top-0 descriptionText${index} invisible`}
                                  >
                                    {journalEntry?.descriptionLine
                                      ? journalEntry?.descriptionLine
                                      : journalEntry?.descriptionEntry}
                                  </div>
                                  <div className="font-normal max-w-full  leading-6">
                                    <Tooltip
                                      content={
                                        (journalEntry?.descriptionLine
                                          ? journalEntry?.descriptionLine
                                              ?.length
                                          : journalEntry?.descriptionEntry
                                              ?.length) > DescriptionLength
                                          ? (() => {
                                              const segmentLength = 55;
                                              const segments = [];
                                              const length =
                                                journalEntry?.descriptionLine
                                                  ? journalEntry
                                                      ?.descriptionLine?.length
                                                  : journalEntry
                                                      ?.descriptionEntry
                                                      ?.length;
                                              for (
                                                let i = 0;
                                                i < length;
                                                i += segmentLength
                                              ) {
                                                segments.push(
                                                  journalEntry?.descriptionLine
                                                    ? journalEntry?.descriptionLine?.substring(
                                                        i,
                                                        i + segmentLength,
                                                      )
                                                    : journalEntry?.descriptionEntry?.substring(
                                                        i,
                                                        i + segmentLength,
                                                      ),
                                                );
                                              }
                                              return segments.map(
                                                (segment, index) => (
                                                  <p key={index}>{segment}</p>
                                                ),
                                              );
                                            })()
                                          : journalEntry?.descriptionLine
                                            ? journalEntry?.descriptionLine
                                            : journalEntry?.descriptionEntry
                                      }
                                      contentClassName={`border-none max-w-[500px] rounded-[10px] overflow-visible text-sm text-slate-700 relative tooltipArrowDown ${
                                        (journalEntry?.descriptionLine
                                          ? journalEntry?.descriptionLine
                                          : journalEntry?.descriptionEntry
                                        )?.length > DescriptionLength
                                          ? ''
                                          : ''
                                      }`}
                                      tooltipShow={
                                        // document.querySelector(
                                        //   `.descriptionText${index}`
                                        // )?.clientWidth >
                                        // descriptionTDRef.current
                                        //   ?.clientWidth
                                        (journalEntry?.descriptionLine
                                          ? journalEntry?.descriptionLine
                                          : journalEntry?.descriptionEntry
                                        )?.length > DescriptionLength
                                      }
                                    >
                                      <div className="max-w-full">
                                        {(journalEntry?.descriptionLine
                                          ? journalEntry?.descriptionLine
                                          : journalEntry?.descriptionEntry
                                        )?.length > DescriptionLength
                                          ? `${(journalEntry?.descriptionLine
                                              ? journalEntry?.descriptionLine
                                              : journalEntry?.descriptionEntry
                                            )?.slice(0, DescriptionLength)}...`
                                          : journalEntry?.descriptionLine
                                            ? journalEntry?.descriptionLine
                                            : journalEntry?.descriptionEntry}
                                        {/* {journalEntry?.descriptionEntry?.slice(
                                                0,
                                                30
                                              ) + "..."} */}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </td>
                              {(() => {
                                const name = journalEntry?.account?.name || '';
                                const mask = journalEntry?.account?.mask || '';
                                const slicedName =
                                  name.length > accountLength
                                    ? `${name.slice(0, accountLength)}...`
                                    : name;
                                const combinedAccount = mask
                                  ? `${slicedName} (...${mask})`
                                  : slicedName;
                                return (
                                  <td className="px-2 first:pl-0 last:pr-5 py-2.5 whitespace-nowrap text-slate-700 font-sm font-normal leading-6">
                                    <Tooltip
                                      tooltipShow={name.length > accountLength}
                                      content={`${journalEntry?.account?.name}${
                                        journalEntry?.account?.mask
                                          ? ` (...${journalEntry?.account?.mask})`
                                          : ''
                                      }`}
                                    >
                                      {combinedAccount}
                                    </Tooltip>
                                  </td>
                                );
                              })()}
                              {/* <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap w-[8%]">
                                    <div className=" text-left">
                                      {journalEntry?.customer?.customer ||
                                        "---"}
                                    </div>
                                  </td> */}
                              <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap ">
                                <div className="text-left">
                                  {journalEntry?.accountType}
                                </div>
                              </td>
                              <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap ">
                                <Tooltip
                                  tooltipShow={journalEntry?.tags?.length > 1}
                                  content={journalEntry?.tags?.map((t, i) => (
                                    <p
                                      key={t?.id}
                                      className="text-sm text-center"
                                    >
                                      {t?.name}
                                      {journalEntry?.tags?.length - 1 !== i &&
                                        ','}
                                    </p>
                                  ))}
                                  contentClassName="min-w-[80px] cursor-default"
                                  position="bottom"
                                  className=""
                                >
                                  <div className="w-fit text-sm  text-nowrap cursor-default pb-px px-1  leading-tight rounded-sm">
                                    {trimText(journalEntry?.tags?.[0]?.name, 8)}{' '}
                                    {journalEntry?.tags?.length > 1 &&
                                      `+${journalEntry?.tags?.length - 1}`}
                                  </div>
                                </Tooltip>
                              </td>
                              {/* <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap w-[9%]">
                                    <div className=" text-left">
                                      $
                                      {journalEntry?.amount
                                        ? parseFloat(
                                            journalEntry?.amount
                                          )?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        : ""}
                                    </div>
                                  </td> */}

                              <td className="px-2 first:pl-0 last:pr-5 text-sm py-1 whitespace-nowrap">
                                <div className=" text-left">
                                  {journalEntry?.amount
                                    ?.toString()
                                    .charAt(0) === '-'
                                    ? '-'
                                    : ''}
                                  {getCurrencySymbolFromIso(
                                    journalEntry?.currency,
                                    currencies,
                                  )}
                                  {parseFloat(
                                    journalEntry?.amount
                                      ?.toString()
                                      .replace('-', ''),
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              </td>
                              {/* <td className="whitespace-nowrap min-w-[100px] w-[100px]">
                                    {index === 0 &&
                                      !journalEntry?.systemCreated && (
                                        <div className=" inline-flex items-center gap-3.5">
                                          {!(accessType === VIEWER) && (
                                            <Tooltip
                                              content="Edit Journal Entry Group"
                                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                            >
                                              <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                                <svg
                                                  width="28"
                                                  height="28"
                                                  viewBox="0 0 28 28"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setFlyoutOpen(false);
                                                    setTitle(
                                                      "Edit Journal Entry"
                                                    );
                                                    setActiveEntry(
                                                      journalEntries
                                                    );
                                                    setFormOpen(true);
                                                  }}
                                                >
                                                  <path
                                                    d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                    fill="white"
                                                    stroke="#D0D5DD"
                                                    strokeOpacity="0.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                                    stroke="#858D9D"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </div>
                                            </Tooltip>
                                          )}
                                          {!(accessType === VIEWER) && (
                                            <Tooltip
                                              content="Delete Journal Entry"
                                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                            >
                                              <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                                <svg
                                                  width="28"
                                                  height="28"
                                                  viewBox="0 0 28 28"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setErrMessage("");
                                                    setFlyoutOpen(false);
                                                    setActiveEntry(
                                                      journalEntries
                                                    );
                                                    setModalOpen(true);
                                                  }}
                                                >
                                                  <path
                                                    d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                    fill="white"
                                                    stroke="#D0D5DD"
                                                    strokeOpacity="0.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                                    stroke="#858D9D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M12.693 16.3574H14.8505"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M12.1552 13.7656H15.3948"
                                                    stroke="#667085"
                                                    strokeOpacity="0.8"
                                                    stroke-width="1.3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                      )}
                                  </td> */}
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  )}
                </table>
                {!results?.length > 0 && (
                  <div className="flex flex-col gap-5 items-center justify-center w-full">
                    <h6 className="text-center mt-6">No Expenses Found</h6>
                  </div>
                )}
              </div>
            )}
            {!isLoading && results?.length > 0 && (
              <div className="mt-10">
                <PaginationClassic
                  setLimit={setLocalLimit}
                  onPageChange={(v) => getData({ ...v, loading: true })}
                  pagination={{ limit: localLimit, page, totalRecords }}
                  pageLoad
                />
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default ProjectExpense;
