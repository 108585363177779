import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { toast } from 'react-toastify';
import MembersModal from './MembersModal';
import MembersDropdown from './MembersDropdown';
import {
  changeMemberRole,
  deleteInvitedMemberApi,
  getMembersApi,
  removeUserFromTeamApi,
  addTeamMember,
  deleteTeamApi,
} from '../../../API/backend_helper';
import { OWNER, TEAM_ID, TEAM_NAME } from '../../../utils/Utils';
import Tooltip from '../../../components/Tooltip';
import LeaveModal from '../../manageWorkspaces/manage/LeaveModal';
import DeleteUserModal from './DeleteUserModal';

const Members = () => {
  const navigate = useNavigate();
  const TeamId = localStorage.getItem(TEAM_ID);
  const TeamName = localStorage.getItem(TEAM_NAME);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [localInvite, setLocalInvite] = useState({});
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [activeDeleteUser, setActiveDeleteUser] = useState({});

  const { accessType, localUser, permissions } = useSelector(
    (state) => state.User,
  );

  const [deleteWorkspace, setDeleteWorkspace] = useState(false);

  const getMembers = async (isLoading = false) => {
    if (!loading && isLoading) {
      setLoading(true);
    }
    try {
      const res = await getMembersApi();
      setMembers(res);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMembers(true);
  }, []);

  const deleteInvitedMember = async (id) => {
    try {
      const res = await deleteInvitedMemberApi(id);
      getMembers();
    } catch (err) {
      console.log('err', err);
    }
  };

  const removeMemberFromTeam = async () => {
    if (!activeDeleteUser?.id) {
      return;
    }
    try {
      await removeUserFromTeamApi(activeDeleteUser?.id);
      await getMembers();
    } catch (err) {
      console.log('err', err);
    }
  };

  const resendInvite = async (invite) => {
    setLocalInvite(invite);
    setIsOpenModal(true);
  };

  const onRoleChange = async (id, role) => {
    try {
      const res = await changeMemberRole(id, { newRole: role });
      toast.success('User role changed successfully.');
      getMembers();
    } catch (err) {
      console.log('err', err);
    }
  };

  const onDeleteWorkspace = async () => {
    setLoading(true);
    try {
      const res = await deleteTeamApi(TeamId);
      localStorage.setItem(TEAM_ID, '');
      localStorage.setItem(TEAM_NAME, '');
      setLoading(false);
      navigate('/manage-workspaces');
    } catch (err) {
      setLoading(false);
      setDeleteWorkspace(false);
      console.log('err', err);
    }
  };

  return (
    <div className="flex overflow-hidden" style={{ width: '100%' }}>
      <DeleteUserModal
        isOpen={deleteUserModalOpen}
        setIsOpen={setDeleteUserModalOpen}
        activeUser={activeDeleteUser}
        handleDone={removeMemberFromTeam}
      />
      <MembersModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        getMembers={getMembers}
        localInvite={localInvite}
        setLocalInvite={setLocalInvite}
      />
      <LeaveModal
        isOpen={deleteWorkspace}
        title="Delete Workspace"
        description={`Are you sure you want to delete "${TeamName}"? This action cannot be undone.`}
        setIsOpen={setDeleteWorkspace}
        onCompletion={onDeleteWorkspace}
        loading={loading}
        team={deleteWorkspace}
      />
      <div className="relative flex  flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-[#182235]">
        {loading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <main className="grow pb-4 w-full max-w-9xl mx-auto">
            <div className="flex items-center justify-between mb-7  ">
              <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
                Members
              </h1>
              {checkPermission(
                PermissionKeys.INVITE_USERS,
                accessType,
                permissions,
              ) && (
                <button
                  className="btn !bg-indigo-500 text-base hover:!bg-indigo-600 text-white"
                  aria-controls="basic-modal"
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Add Members
                </button>
              )}
            </div>
            <div
              className="bg-white flex-1 dark:bg-slate-800 rounded-sm  overflow-x-auto"
              id="manageTeamTable"
            >
              <table className="table-auto w-full dark:text-slate-300 mb-[5.938rem]">
                {/* Table header */}
                <thead className="text-sm shadow-md text-[#667085] dark:text-slate-400 border-none ">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Name</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Email</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Access Level</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" />
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm  divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                  {/* Row */}
                  {members?.invites?.map?.((invite, index) => (
                    <tr key={index} className="border-b border-slate-300">
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center text-slate-800 pr-3 w-max">
                          <div className=" shrink-0 mr-2 sm:mr-3 ">
                            {false ? (
                              <img
                                className="ml-1 w-10 h-10 rounded-full"
                                src="	https://plaid-merchant-logos.plaid.com/uber_1060.png"
                                width="20"
                                height="20"
                                alt="uber"
                              />
                            ) : (
                              <div className="w-10 h-10 flex items-center justify-center bg-slate-400 dark:bg-slate-700 rounded-full">
                                <div className="capitalize text-white font-bold text-2xl">
                                  {invite?.email?.charAt?.(0)}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="text-sm text-[#667085] dark:text-slate-100">
                            {invite?.email?.split('@')?.[0]}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {invite?.email}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {invite?.declined
                            ? 'Invite Declined'
                            : 'Pending Invite'}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="text-[#667085] dark:text-slate-100">
                          <button
                            onClick={() => deleteInvitedMember(invite?.id)}
                            type="button"
                            className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085]"
                          >
                            Delete
                          </button>
                          {invite?.declined && (
                            <button
                              onClick={() => resendInvite(invite)}
                              type="button"
                              className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085]"
                            >
                              Resend Invite
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {members?.users?.map?.((d, index) => (
                    <tr key={index} className="border-b border-slate-300">
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center text-slate-800 pr-3 w-max">
                          <div className=" shrink-0 mr-2 sm:mr-3 ">
                            {false ? (
                              <img
                                className="ml-1 w-10 h-10 rounded-full"
                                src="	https://plaid-merchant-logos.plaid.com/uber_1060.png"
                                width="20"
                                height="20"
                                alt="uber"
                              />
                            ) : (
                              <div className="w-10 h-10 flex items-center justify-center bg-slate-400 dark:bg-slate-700 rounded-full">
                                {d.avatarUrl ? (
                                  <img
                                    src={d.avatarUrl}
                                    alt={`${d.firstName} Avatar`}
                                    className="w-10 h-10 rounded-full"
                                  />
                                ) : (
                                  <div className="capitalize text-white font-bold text-lg">
                                    {d?.firstName?.charAt?.(0)}
                                    {d?.lastName?.charAt?.(0)}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className=" text-[#667085] dark:text-slate-100">
                            {d?.firstName} {d?.lastName}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {d?.email}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="flex">
                          {d?.credentials?.roles?.[0] === OWNER ? (
                            <Tooltip
                              content="Owner role cannot be changed"
                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            >
                              {' '}
                              <div className="cursor-default capitalize text-[#667085] dark:text-slate-100">
                                {d?.credentials?.roles?.[0]}
                              </div>
                            </Tooltip>
                          ) : (
                            <div className=" text-[#667085] dark:text-slate-100">
                              <MembersDropdown
                                onChangeValue={onRoleChange}
                                value={d?.credentials?.roles?.[0]}
                                id={d?.id}
                                disabled={
                                  d?.email === localUser?.email ||
                                  !checkPermission(
                                    PermissionKeys.MANAGE_WORKSPACE,
                                    accessType,
                                    permissions,
                                  )
                                }
                                isTableDropdown
                                bottomOfTable
                                ids={[
                                  'manageWorkspaceContainer',
                                  'manageTeamTable',
                                ]}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="flex justify-center">
                          {checkPermission(
                            PermissionKeys.MANAGE_WORKSPACE,
                            accessType,
                            permissions,
                          ) &&
                          d?.credentials?.roles?.[0] === OWNER &&
                          accessType === OWNER ? (
                            <button
                              onClick={() => setDeleteWorkspace(true)}
                              type="button"
                              disabled={accessType !== OWNER}
                              className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085]"
                            >
                              Delete Workspace
                            </button>
                          ) : (
                            checkPermission(
                              PermissionKeys.REMOVE_USERS,
                              accessType,
                              permissions,
                            ) && (
                              <button
                                onClick={() => {
                                  setActiveDeleteUser(d);
                                  setDeleteUserModalOpen(true);
                                }}
                                type="button"
                                disabled={
                                  d?.credentials?.roles?.[0] === OWNER ||
                                  d?.email === localUser?.email
                                }
                                className="ms-2 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085] disabled:cursor-not-allowed"
                              >
                                Delete
                              </button>
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default Members;
