import resolveConfig from 'tailwindcss/resolveConfig';
import moment from 'moment';

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig('./src/css/tailwind.config.js');
};

export const checkVideoByType = (string = '') => {
  const Types = ['mp4', 'mov', 'webm', 'quicktime'];
  let isVideoType = false;
  Types.forEach((type) => {
    if (string?.toLocaleLowerCase()?.includes(type)) {
      isVideoType = true;
    }
  });
  return isVideoType;
};

export const checkVideoByLink = (string = '') => {
  const Types = ['.mp4', '.mov', '.webm'];
  let isVideoType = false;
  Types.forEach((type) => {
    if (string?.toLocaleLowerCase()?.includes(type)) {
      isVideoType = true;
    }
  });
  return isVideoType;
};

export const checkImageByType = (string = '') => {
  const Types = ['svg', 'jpeg', 'jpg', 'png'];
  let isImageType = false;
  Types.forEach((type) => {
    if (string?.toLocaleLowerCase()?.includes(type)) {
      isImageType = true;
    }
  });
  return isImageType;
};

export const checkImageByLink = (string = '') => {
  const Types = ['.svg', '.jpeg', '.jpg', '.png'];
  let isImageType = false;
  Types.forEach((type) => {
    if (string?.toLocaleLowerCase()?.includes(type)) {
      isImageType = true;
    }
  });
  return isImageType;
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const getStringSegments = (string = '', segmentLength = 50) => {
  const segments = [];
  let lastIndex = 0;
  while (lastIndex < string?.length) {
    let endIndex = Math.min(lastIndex + segmentLength, string?.length);
    if (endIndex < string?.length && string?.[endIndex] !== ' ') {
      const lastSpaceIndex = string?.lastIndexOf(' ', endIndex);
      endIndex = lastSpaceIndex > lastIndex ? lastSpaceIndex : endIndex;
    }
    segments.push(string?.substring(lastIndex, endIndex));

    if (string?.[endIndex] !== ' ') {
      lastIndex = endIndex;
    } else {
      lastIndex = endIndex + 1;
    }
  }
  return segments;
};

export const formatValue = (value) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 4,
    notation: 'compact',
  }).format(value);

export const formatThousands = (value) =>
  Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(value);

export const formatNumber = (value) => {
  const num = Number(value).toFixed(2);

  if (parseFloat(num) < 1000) {
    return num;
  } else {
    const parts = num.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
};

export const amountColor = (amount) => {
  switch (amount?.charAt?.(0)) {
    case '-':
      return 'text-[#40AA00] ';
    default:
      return 'text-[#535C6E]';
  }
};

export const getBillStatusColor = (status) => {
  switch (status) {
    case 'partial':
      return 'text-[#EDB900] bg-[#EDB9001A]';
    case 'unpaid':
      return 'text-blue-500 bg-blue-100';
    case 'draft':
      return 'text-slate-500 bg-slate-100';
    case 'paid':
      return 'text-[#40AA00] bg-[#40AA001A]';
    default:
      return 'text-[#FF4B4B] bg-[#FF4B4B1A]';
  }
};

export const getContrast = (hexcolor, darkText, lightText) => {
  if (hexcolor) {
    hexcolor = hexcolor?.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? darkText : lightText;
  }
};
export const panelAmountColor = (amount) => {
  switch (String(amount)?.charAt?.(0)) {
    case '-':
      return 'text-[#40AA00]';
    default:
      return 'text-[#333333]';
  }
};

export const statusColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'border border-[#40AA00] bg-[#40AA00] bg-opacity-5 dark:bg-emerald-400/30 text-[#40AA00] dark:text-emerald-400';
    case 'Canceled':
      return 'border border-slate-100 bg-slate-100/50 dark:bg-slate-900 text-slate-500 dark:text-slate-400';
    default:
      return 'border border-slate-300  text-slate-500 bg-slate-100';
  }
};

export const lightenColor = (hex, percent) => {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  r = parseInt((r * (100 + percent)) / 100);
  g = parseInt((g * (100 + percent)) / 100);
  b = parseInt((b * (100 + percent)) / 100);

  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  return (
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0')
  );
};

// Function to remove duplicates based on a property (e.g., 'id')
export const removeDuplicates = (array, property) => {
  const seen = new Set();
  return array.filter((obj) => {
    const value = obj[property];
    if (seen.has(value)) {
      return false; // Skip duplicate
    }
    seen.add(value);
    return true;
  });
};

export const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const Expense = 'Expense';
export const Transfer = 'Transfer';
export const Income = 'Income';
export const SyncBankAccount = 'syncBankAccount';
export const ImportStatement = 'importStatement';

export const extractValuesByKey = (obj, key) => {
  // Get an array of all values
  const allValues = Object.values(obj);

  // Use map() to extract the values of the specified key
  const valuesByKey = allValues.map((arr) => arr.map((item) => item[key]));

  // Use flat() or concat() to flatten the arrays
  // You can use flat() if your environment supports it
  // or use concat.apply([], valuesByKey) for broader compatibility
  const flattenedValues = [].concat.apply([], valuesByKey);

  return flattenedValues;
};

export const ACCOUNT_TYPES = [
  {
    label: 'Assets',
    value: 'Assets',
  },
  {
    label: 'Liabilities',
    value: 'Liabilities',
  },
  {
    label: 'Equity',
    value: 'Equity',
  },
  {
    label: 'Income',
    value: 'Income',
  },
  {
    label: 'Expenses',
    value: 'Expenses',
  },
];

export const TRANSACTION_TYPES = [
  {
    label: 'Expense',
    value: 'Expense',
  },
  {
    label: 'Income',
    value: 'Income',
  },
  {
    label: 'Transfer',
    value: 'Transfer',
  },
];

export const TRANSACTION_STATUS = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Needs Review',
    value: 'review',
  },
  {
    label: 'Reviewed',
    value: 'reviewed',
  },
  {
    label: 'Reconciled',
    value: 'reconciled',
  },
  {
    label: 'Excluded',
    value: 'excluded',
  },
];

export const areArraysEqual = (array1 = [], array2 = []) => {
  // Check if both arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Create objects to store the counts of elements in each array
  const countMap1 = {};
  const countMap2 = {};

  // Count the occurrences of each element in the first array
  for (const element of array1) {
    countMap1[element] = (countMap1[element] || 0) + 1;
  }

  // Count the occurrences of each element in the second array
  for (const element of array2) {
    countMap2[element] = (countMap2[element] || 0) + 1;
  }

  // Compare the counts of each element
  for (const key in countMap1) {
    if (countMap1[key] !== countMap2[key]) {
      return false;
    }
  }

  // If all counts are equal, the arrays are considered equal
  return true;
};

export const TEAM_ID = 'teamId';
export const TEAM_CURRENCY = 'teamCurrency';
export const TEAM_DATE_FORMAT = 'teamDateFormat';
export const TEAM_NAME = 'teamName';
export const STAFF = 'staff';
export const SUPPORT = 'staff-support';
export const IS_PERSON = 'IS_PERSON';
export const IS_ACCOUNTANT = 'IS_ACCOUNTANT';
//roles
export const OWNER = 'owner';
export const ADMIN = 'admin';
export const ACCOUNTANT = 'accountant';
export const VIEWER = 'viewer';

//Settings Tab
export const S_USER_DETAILS = 's-user-details';
export const S_MEMBERS = 's-members';
export const S_MANANGE_MEMBERS = 's-manage-members';
export const S_CONNECTIONS = 's-connections';
export const S_CONFIGURATION = 's-configuration';
export const S_CATEGORIES = 's-categories';
export const S_TAGS = 's-tags';
export const S_MERCHANTS = 's-merchants';
export const S_BILLING = 's-billing';
export const S_TAXES = 's-taxes';
export const S_PAYMENT_SETUP = 's-paymentSetup';
export const S_APP_INTEGRATIONS = 's-appIntegrations';
export const S_AUDIT = 's-audit';

export const calculatePercentageDifference = (
  previousYearExpense,
  thisYearExpense,
) => {
  // Calculate the absolute difference between the two values
  const difference = Math.abs(+thisYearExpense - +previousYearExpense);

  // Calculate the percentage difference
  const percentageDifference =
    (difference / Math.abs(previousYearExpense)) * 100;

  return percentageDifference?.toFixed(2);
};

export const getAllPastMonths = () => {
  const currentYear = moment().year();
  const currentMonth = moment().month(); // Month index starts from 0

  const result = [];

  // Iterate over the previous year
  if (currentMonth < 11) {
    const prevYear = currentYear - 1;
    const prevYearData = {
      year: prevYear,
      months: [],
    };
    for (let month = 0; month <= 11; month++) {
      const monthData = {
        id: moment({ year: prevYear, month }).format('MMMM,YYYY'),
        name: moment({ year: prevYear, month }).format('MMMM'),
      };
      prevYearData.months.unshift(monthData);
    }
    result.push(prevYearData);
  }

  // Iterate over the current year
  const currentYearData = {
    year: currentYear,
    months: [],
  };
  for (let month = 0; month <= currentMonth; month++) {
    const monthData = {
      id: moment({ year: currentYear, month }).format('MMMM,YYYY'),
      name: moment({ year: currentYear, month }).format('MMMM'),
    };
    currentYearData.months.unshift(monthData);
  }
  result.push(currentYearData);

  return result?.reverse();
};

export const generateDates = (month, year) => {
  if (month) {
    const startDate = moment(`${year}-${month}-01`, 'YYYY-MM-DD');
    const endDate = moment(startDate).endOf('month');
    const datesArray = [];

    while (startDate.isSameOrBefore(endDate)) {
      datesArray.push(startDate.format('MM-DD-YYYY'));
      startDate.add(1, 'days');
    }

    return datesArray;
  } else {
    return [
      `01-31-${year}`,
      `02-28-${year}`,
      `03-31-${year}`,
      `04-30-${year}`,
      `05-31-${year}`,
      `06-30-${year}`,
      `07-31-${year}`,
      `08-31-${year}`,
      `09-30-${year}`,
      `10-31-${year}`,
      `11-30-${year}`,
      `12-31-${year}`,
    ];
  }
};

// Handle cropping image

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  );

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement('canvas');

  const croppedCtx = croppedCanvas.getContext('2d');

  if (!croppedCtx) {
    return null;
  }

  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, 'image/png');
  });
}

// End image cropping

export const blobUrlToBlob = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

export const blobToFile = (blob, fileName) => {
  return new File([blob], fileName, { type: blob.type });
};

export const getInvoiceStatusColor = (status) => {
  switch (status) {
    case 'partial':
      return 'text-[#EDB900] bg-[#EDB9001A]';
    case 'unpaid':
      return 'text-blue-500 bg-blue-100';
    case 'paid':
      return 'text-[#40AA00] bg-[#40AA001A]';
    case 'Draft':
      return 'text-slate-500 bg-slate-200';
    case 'overdue':
      return 'text-[#FF4B4B] bg-[#FF4B4B1A]';
    case 'pending':
      return 'text-[#EDB900] bg-[#EDB9001A]';
    case 'accepted':
      return 'text-[#40AA00] bg-[#40AA001A]';
    case 'expired':
      return 'text-[#FF4B4B] bg-[#FF4B4B1A]';
    default:
      return 'text-slate-500 bg-slate-100';
  }
};

export const getBillEventStatusColor = (status) => {
  switch (status) {
    case 'processing':
      return 'text-[#EDB900] bg-[#EDB9001A]';
    case 'skipped':
      return 'text-slate-500 bg-slate-100';
    case 'received':
      return 'text-[#40AA00] bg-[#40AA001A]';
    case 'success':
      return 'text-[#40AA00] bg-[#40AA001A]';
    case 'error':
      return 'text-[#FF4B4B] bg-[#FF4B4B1A]';
    default:
      return 'text-slate-500 bg-slate-100';
  }
};

export const getAppStatusColor = (status) => {
  switch (status) {
    case 'partial':
      return 'text-[#EDB900] bg-[#EDB9001A]';
    case 'unpaid':
      return 'text-slate-500 bg-slate-100';
    case 'completed':
      return 'text-[#40AA00] bg-[#40AA001A]';
    case 'Draft':
      return 'text-slate-500 bg-slate-200';
    case 'overdue':
      return 'text-[#FF4B4B] bg-[#FF4B4B1A]';
    case 'pending':
      return 'text-[#EDB900] bg-[#EDB9001A]';
    case 'accepted':
      return 'text-[#40AA00] bg-[#40AA001A]';
    case 'expired':
      return 'text-[#FF4B4B] bg-[#FF4B4B1A]';
    default:
      return 'text-slate-500 bg-slate-100';
  }
};

export const getGustoStatus = (status) => {
  switch (status) {
    case 'syncing':
      return 'text-[#EDB900] bg-[#EDB9001A]';
    case 'completed':
      return 'text-[#40AA00] bg-[#40AA001A]';
    case 'not_started':
      return 'text-slate-500 bg-slate-200';
    default:
      return 'text-slate-500 bg-slate-100';
  }
};

export const recurringTransStatusColor = (status) => {
  switch (status) {
    case 'paid':
      return 'text-[#40AA00] bg-[#40AA001A]';
    case 'overdue':
      return 'text-[#FF4B4B] bg-[#FF4B4B1A]';
    default:
      return 'text-slate-500 bg-slate-100';
  }
};

export const getExpenseReportStatusColor = (status) => {
  switch (status) {
    case 'Approved':
      return 'text-[#40AA00] bg-[#40AA001A]';
    case 'Denied':
      return 'text-[#FF4B4B] bg-[#FF4B4B1A]';
    case 'Submitted':
      return 'text-[#EDB900] bg-[#EDB9001A]';
    case 'Need Review':
      return 'text-[#01579B] bg-[#E0F7FA]';
    default:
      return 'text-slate-500 bg-slate-100';
  }
};

export const getWarningMessage = (
  firstDueDate,
  repeat,
  ends,
  lastDate,
  occurrences,
) => {
  return `Recurring bill starts on ${
    firstDueDate ? moment(firstDueDate).format('MMM Do YYYY') : '--'
  }, repeats ${repeat} and ${
    ends === 'after'
      ? `lasts for ${occurrences} occurrences`
      : ends === 'on'
        ? `last payment on ${
            lastDate ? moment(lastDate).format('MMM Do YYYY') : '--'
          }`
        : 'continues indefinitely'
  }`;
};

export const subscriptionPlanDummyId = 123456789;

export const isPathAllowed = (pathname, array) => {
  // Check if any element of the allowed array is included in the pathname
  return array.some((path) => pathname.includes(path));
};

export const transformAccounts = (allAccounts, anchorTier, type) => {
  let typeIds;

  type === 'expense'
    ? (typeIds = {
        Expenses: 1,
        Assets: 2,
        Liabilities: 3,
        Equity: 4,
        Income: 5,
      })
    : (typeIds = {
        Income: 1,
        Assets: 2,
        Liabilities: 3,
        Equity: 4,
        Expenses: 5,
      });

  let filteredAccounts;

  if (anchorTier === 'CATEGORY_PHYSICAL_ACCOUNT') {
    filteredAccounts = allAccounts?.filter(
      (account) =>
        account?.subType?.anchorTier === anchorTier ||
        account?.type === 'Income' ||
        account?.type === 'Expenses',
    );
  } else if (anchorTier === 'bills') {
    filteredAccounts = allAccounts?.filter(
      (account) => account.type === 'Expenses',
    );
  } else if (anchorTier === 'income') {
    filteredAccounts = allAccounts?.filter(
      (account) => account.type === 'Income',
    );
  } else if (anchorTier === 'allAccounts') {
    filteredAccounts = allAccounts;
  } else if (anchorTier === 'PHYSICAL_ACCOUNT') {
    filteredAccounts = allAccounts?.filter(
      (account) =>
        account?.subType?.anchorTier === anchorTier ||
        account?.subType?.name === 'Other Short-Term Asset' ||
        account?.subType?.name === 'Other Long-Term Asset',
    );
  } else if (anchorTier === 'EXPENSE_PHYSICAL_ACCOUNT') {
    filteredAccounts = allAccounts?.filter(
      (account) =>
        account?.subType?.anchorTier === 'PHYSICAL_ACCOUNT' ||
        ((account?.subType?.name === 'Other Short-Term Asset' ||
          account?.subType?.name === 'Other Long-Term Asset') &&
          account?.type === 'Expenses'),
    );
  } else {
    filteredAccounts = allAccounts?.filter(
      (account) => account?.subType?.anchorTier === anchorTier,
    );
  }

  const groupedByType = filteredAccounts.reduce((acc, current) => {
    const type = current.type;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(current);
    return acc;
  }, {});

  for (const type in groupedByType) {
    groupedByType[type].sort((a, b) => a.name.localeCompare(b.name));
  }

  const transformed = Object.keys(groupedByType).map((type) => ({
    id: typeIds[type],
    name: type,
    categories: groupedByType[type],
  }));

  transformed.sort((a, b) => a.id - b.id);

  return transformed;
};

export const calculatePercentage = (acquired, total) => {
  // Handling cases where either reviewedCount or allReviewedCount is zero
  if (total === 0) {
    if (acquired === 0) {
      return 100; // If both are zero, return 0
    } else {
      return 100; // If allReviewedCount is zero but reviewedCount isn't, return Infinity
    }
  }
  const percentage = (acquired / total) * 100;
  // Calculating the percentage
  return Math.floor(percentage);
};

export function dateCategoryList(years = false) {
  const adjustToStartOfDay = (date) => {
    return new Date(date.setHours(0, 0, 0, 0));
  };

  const lastDayOfMonth = (year, month) => {
    return new Date(year, month, 0);
  };

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const today = adjustToStartOfDay(new Date());
    const years = [];
    for (let i = 0; i < 3; i++) {
      const year = currentYear - i;
      years.push({
        id: year.toString().slice(2),
        name: year.toString(),
        startDate: adjustToStartOfDay(new Date(year, 0, 1)),
        endDate:
          year === currentYear
            ? today
            : adjustToStartOfDay(new Date(year, 11, 31)),
      });
    }
    return years;
  };

  const generateQuarters = () => {
    const quarters = [];
    const currentYear = new Date().getFullYear();
    const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);
    const today = adjustToStartOfDay(new Date());
    for (let year = currentYear; year >= currentYear - 3; year--) {
      for (let quarter = 4; quarter >= 1; quarter--) {
        if (year === currentYear && quarter > currentQuarter) {
          continue;
        }
        const quarterStartDate = new Date(year, quarter * 3 - 3, 1);
        const quarterEndDate = lastDayOfMonth(year, quarter * 3);
        quarters.push({
          id: `0${quarter}${year.toString().slice(2)}`,
          name: `Q${quarter} ${year}`,
          startDate: adjustToStartOfDay(quarterStartDate),
          endDate:
            year === currentYear && quarter === currentQuarter
              ? today
              : adjustToStartOfDay(lastDayOfMonth(year, quarter * 3)),
        });
      }
    }
    return quarters;
  };

  const generateMonths = () => {
    const months = [];
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const today = adjustToStartOfDay(new Date());
    for (let year = currentYear; year >= currentYear - 3; year--) {
      for (let month = 12; month >= 1; month--) {
        if (year === currentYear && month > currentMonth) {
          continue;
        }
        const monthStartDate = new Date(year, month - 1, 1);
        const monthEndDate = lastDayOfMonth(year, month);
        months.push({
          id: `${month}${year.toString().slice(2)}`,
          name: `${monthStartDate.toLocaleString('default', {
            month: 'short',
          })} ${year}`,
          startDate: adjustToStartOfDay(monthStartDate),
          endDate:
            year === currentYear && month === currentMonth
              ? today
              : adjustToStartOfDay(lastDayOfMonth(year, month)),
        });
      }
    }
    return months;
  };

  const dataList = years
    ? [{ name: 'Calendar Year', categories: generateYears() }]
    : [
        { name: 'Calendar Year', categories: generateYears() },
        { name: 'Calendar Quarter', categories: generateQuarters() },
        { name: 'Calendar Months', categories: generateMonths() },
      ];

  return dataList;
}

export function dateCategoryListWithWeeks() {
  const adjustToStartOfDay = (date) => new Date(date.setHours(0, 0, 0, 0));

  // Function to calculate start of the current week (Monday)
  const generateThisWeek = () => {
    const today = adjustToStartOfDay(new Date());
    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(
      today.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)),
    );

    return {
      id: 'this_week',
      name: 'This Week',
      startDate: startOfWeek,
      endDate: adjustToStartOfDay(new Date()), // Today
    };
  };

  // Function to calculate start and end dates for the current month
  const generateThisMonth = () => {
    const today = adjustToStartOfDay(new Date());
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    return {
      id: 'this_month',
      name: 'This Month',
      startDate: startOfMonth,
      endDate: adjustToStartOfDay(new Date()), // Today
    };
  };

  // Function to calculate start and end dates for the last month
  const generateLastMonth = () => {
    const today = adjustToStartOfDay(new Date());
    const startOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1,
    );
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month

    return {
      id: 'last_month',
      name: 'Last Month',
      startDate: startOfLastMonth,
      endDate: endOfLastMonth,
    };
  };

  return [
    {
      name: 'Custom',
      categories: [
        {
          name: 'Custom',
          id: 'custom',
        },
      ],
    },
    {
      name: 'By Week',
      categories: [generateThisWeek()],
    },
    {
      name: 'By Month',
      categories: [generateThisMonth(), generateLastMonth()],
    },
  ];
}

export const globalAdjustToStartOfDay = (date) => {
  return new Date(date.setHours(0, 0, 0, 0));
};

export const formatReportData = (data, dateRangekey) => {
  const newData = [];
  const incomeData = data['Income'];
  const costOfGoodsSold = data['Cost of Goods Sold'];
  const expense = data['Operating Expense'];
  newData.push(
    { Income: incomeData },
    { 'Cost of Goods Sold': costOfGoodsSold },
    {
      'Gross Profit': { total: data?.grossProfit, subCategories: [] },
    },
    {
      'Gross Margin': { total: data?.grossMargin ? data?.grossMargin : 0 },
    },
    { 'Operating Expenses': expense },
    {
      'Net Profit': {
        total: data?.netProfit,
        subCategories: [],
      },
    },
    {
      'Net Margin': {
        total: data?.netMargin ? data?.netMargin : 0,
      },
    },
  );

  const formattedData = newData.map((item) => {
    const key = Object.keys(item)[0];
    const subCategories = item[key];
    const total = item[key]?.total.toString();
    return {
      accountLabel: key,
      [dateRangekey]: {
        total: total,
        details: subCategories.subCategories,
      },
    };
  });

  return formattedData;
};

export const DevEnv = 'dev';
export const ProdEnv = 'prod';

export const categorizeByInstitution = (data, defaultName = 'Other') => {
  let categorizedData = {};

  // Iterate through the array of objects
  data.forEach((item) => {
    // Check if the institution exists
    const institutionName = item.institution
      ? item.institution.name
      : defaultName;

    // Check if the institution exists in the categorized data
    if (!categorizedData[institutionName]) {
      // If not, create a new object for that institution
      categorizedData[institutionName] = {
        id: item.institution ? item.institution.id : null,
        name: institutionName,
        color: item.institution ? item.institution.color : '#000000',
        logoUrl: item.institution ? item.institution.logoUrl : null,
        routingNumbers: item.institution
          ? item.institution.routingNumbers
          : null,
        plaidId: item.institution ? item.institution.plaidId : null,
        plaidPopular: item.institution ? item.institution.plaidPopular : null,
        plaidDisplayOrder: item.institution
          ? item.institution.plaidDisplayOrder
          : null,
      };
    }

    if (!categorizedData[institutionName].data) {
      categorizedData[institutionName].data = [];
    }
    categorizedData[institutionName].data.push(item);
  });

  // Convert the object to an array of objects
  let result = Object.keys(categorizedData).map((key) => categorizedData[key]);

  return result.reverse();
};

export const formatValueWithSign = (
  value,
  isPercent = false,
  currencySymbol = '',
) => {
  currencySymbol = currencySymbol ? currencySymbol : '$';
  value = parseFloat(value);
  return (
    `
  ${
    !isPercent ? (value >= 0 ? currencySymbol : `-${currencySymbol}`) : ''
  } ${parseFloat(
    value?.toString().replace('-', isPercent ? '-' : ''),
  )?.toLocaleString(undefined, {
    minimumFractionDigits: isPercent ? 0 : 2,
    maximumFractionDigits: isPercent ? 0 : 2,
  })}${isPercent ? '%' : ''}` || `${currencySymbol}0.00`
  );
};

export const getCurrencySymbolFromIso = (isoCode, _currencies) => {
  const currency = _currencies?.find?.((c) => c?.isoCode === isoCode);
  return currency?.symbol === '$' && currency?.isoCode != 'USD'
    ? currency?.symbol
    : currency?.symbol;
};

// Fix timezone, accepts a date formatted like yyyy-MM-DD
export const timezoneAdjustment = (date) => {
  return new Date(
    new Date(`${date}T00:00:00Z`).getTime() +
      new Date(`${date}T00:00:00Z`).getTimezoneOffset() * 60000,
  );
};

export const PEOPLE_TYPES = [
  {
    id: 'Employee',
    name: 'Employee',
    details:
      'An individual who works for a company on a full-time or part-time basis, receiving wages and benefits as part of formal employment.',
  },
  {
    id: 'Contractor',
    name: 'Contractor',
    details:
      'A self-employed individual or company hired to perform specific tasks or projects for a business, typically for a fixed duration or contract period.',
  },
  {
    id: 'Volunteer',
    name: 'Volunteer',
    details:
      'An individual who offers their time and skills to a business or organization without receiving financial compensation.',
  },
  {
    id: 'Donor',
    name: 'Donor',
    details:
      'An individual or entity that provides financial or material contributions to support a business or organization.',
  },
  {
    id: 'Other',
    name: 'Other',
    details:
      "Any individual whose association with the business doesn't fall under the above categories.",
  },
];

export const capitalizeFirstChar = (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const trimText = (text, maxLength) => {
  return text?.length > maxLength
    ? text?.substring(0, maxLength) + '...'
    : text;
};

export const sortObjectsArray = (arr, key, order = 'asc') => {
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((value, key) => value && value[key], obj);
  };

  return arr.sort((a, b) => {
    const aValue = getNestedValue(a, key);
    const bValue = getNestedValue(b, key);

    if (aValue === null) return 1; // Handle null values by placing them at the end
    if (bValue === null) return -1;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    return order === 'asc' ? aValue - bValue : bValue - aValue;
  });
};

export const getIncDecAccountTypeStatus = (type, transactionType) => {
  if (type === 'Assets') {
    return transactionType === 'debit' ? 'upArrow' : 'downArrow';
  } else if (type === 'Liabilities' || type === 'Equity' || type === 'Income') {
    return transactionType === 'credit' ? 'upArrow' : 'downArrow';
  } else if (type === 'Expenses') {
    return transactionType === 'debit' ? 'upArrow' : 'downArrow';
  } else {
    // Handle invalid type
    return 'Invalid Type';
  }
};

//regex for validating American, Canandian and New Zealand Phone numbers
export const phoneRegex =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(?:\(\s*\d{3}\s*\))|\d{3})(?:[.-]\s*)?\d{3}(?:[.-]\s*)?\d{4}|\+64\s?[1-9]\d{0,2}\s?\d{1,4}\s?\d{1,4}|\+\d{1,15})$/;

export const hasUpToTwoDecimalPlaces = (number) => {
  // Convert number to string
  let numberStr = number.toString();
  // Check if the string representation of the number has up to 2 decimal places
  if (numberStr.includes('.')) {
    let decimalPart = numberStr.split('.')[1];
    return decimalPart.length <= 2;
  } else {
    return true; // If there's no decimal point, it's up to 2 decimal places by default
  }
};

const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
};

export const replaceKeysWithValuesAndConvertToCamelCase = (obj) => {
  const newObject = {};

  for (const [key, value] of Object.entries(obj || {})) {
    const camelCaseValue = toCamelCase(value);
    newObject[camelCaseValue] = key;
  }

  return newObject;
};

export const removeValueIfNotExistInData = (keysObj = {}, colArray = []) => {
  const colArrayIds = colArray?.map((item) => item?.id?.trim()); // Extract all `id` values from t2

  Object.keys(keysObj)?.forEach((key) => {
    if (!colArrayIds?.includes?.(`${keysObj[key]}`)) {
      keysObj[key] = '--'; // Replace with "--" if value not found in t2 `id`
    }
  });

  return keysObj;
};

export const getCurrencySymbol = (currencyISO) => {
  const currencyMap = {
    nzd: 'NZ$',
    cad: 'C$',
    zar: 'R',
    usd: '$',
  };

  return currencyMap[currencyISO] || '';
};

export const hash = Math.floor(Math.random() * 90000) + 10000;

export const formatDateLocally = (input, format) => {
  // Fallback: Convert to Date if input is not already a Date object
  const date = input instanceof Date ? input : new Date(`${input}T00:00:00`);

  // Helper function to add leading zero if needed
  const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

  const day = addLeadingZero(date.getDate());
  const month = addLeadingZero(date.getMonth() + 1); // Months are 0-indexed
  const year = date.getFullYear();
  if (format === 'dd/mm/yyyy') {
    return `${day}/${month}/${year}`;
  }
  return `${month}/${day}/${year}`;
};

export const getMomentDateFormat = (format) =>
  format.replace(/dd/g, 'DD').replace(/mm/g, 'MM');

export const getFormattedDate = (dateInput, format = null) => {
  if (
    typeof dateInput === 'undefined' ||
    dateInput === 'undefined' ||
    dateInput === null ||
    dateInput === 'null' ||
    dateInput === ''
  ) {
    return ''; // Return empty string for undefined, null, or empty input
  }

  let dateString;

  if (dateInput instanceof Date) {
    // Directly extract year, month, and day from the Date object without using toISOString()
    const year = dateInput.getFullYear();
    const month = String(dateInput.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const day = String(dateInput.getDate()).padStart(2, '0');
    dateString = `${year}-${month}-${day}`;
  } else {
    // Handle string inputs
    const tempDateInput = dateInput.includes('T')
      ? dateInput.split('T')[0]
      : dateInput;

    if (format && typeof format === 'string') {
      const [day, month, year] = format.toLowerCase().split(/[\/-]/);

      if (day === 'dd' && month === 'mm' && year === 'yyyy') {
        const [d, m, y] = tempDateInput.split(/[\/-]/);
        dateString = `${y}-${m}-${d}`;
      } else if (day === 'mm' && month === 'dd' && year === 'yyyy') {
        const [m, d, y] = tempDateInput.split(/[\/-]/);
        dateString = `${y}-${m}-${d}`;
      } else {
        throw new Error('Unsupported date format');
      }
    } else {
      dateString = tempDateInput; // Default case for string inputs
    }
  }

  const [year, month, day] = dateString.split('-');

  // Ensure day, month, and year are valid before formatting
  const formattedYear = year || '';
  const formattedMonth = month?.padStart(2, '0') || '';
  const formattedDay = day?.padStart(2, '0') || '';

  return `${formattedYear}-${formattedMonth}-${formattedDay}`;
};

export const getDatesInRange = (startDate, endDate) => {
  const dates = [];

  const currentDate = new Date(startDate);
  const end = new Date(endDate);

  // Ensure that the date range includes the end date
  while (currentDate <= end) {
    // Format the date manually to avoid time zone issues
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    dates.push(formattedDate);

    // Move to the next date
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const sumObjectValues = (obj) => {
  if (!obj || typeof obj !== 'object') return 0; // Return 0 if obj is not valid

  return Object.values(obj).reduce((sum, value) => {
    if (typeof value === 'number') {
      return sum + value;
    }
    return sum;
  }, 0);
};

export const TaskStatusOptions = [
  {
    name: 'Not Started',
    textColor: '#42B4F7',
    background: '#42B4F71A',
    id: 'notStarted',
  },
  {
    name: 'In Progress',
    textColor: '#EDB900',
    background: '#EDB9001A',
    id: 'inProgress',
  },
  {
    name: 'Review',
    textColor: '#E48642',
    background: '#E486421A',
    id: 'review',
  },
  {
    name: 'Completed',
    textColor: '#78BD4F',
    background: '#40AA001A',
    id: 'completed',
  },
];

export const INVOICE_TAX_TYPES_OPTIONS = [
  {
    id: 'tax-exclusive',
    name: 'Tax Exclusive',
  },
  {
    id: 'tax-inclusive',
    name: 'Tax Inclusive',
  },
  {
    id: 'no-tax',
    name: 'No Tax',
  },
];

export const sortSplitTransactions = (transactions, type) => {
  return transactions.sort((a, b) => {
    const amountA = parseFloat(a.amount.value ?? 0);
    const amountB = parseFloat(b.amount.value ?? 0);

    if (type === 'Income') {
      return amountB - amountA; // Descending order for Income
    } else if (type === 'Expense') {
      return amountA - amountB; // Ascending order for Expense
    }
    return 0; // No sorting if type is neither Income nor Expense
  });
};

export const PnlNoTagValue = 787786786786;
