import React, { useContext, useEffect, useState } from 'react';
import {
  TEAM_DATE_FORMAT,
  VIEWER,
  getFormattedDate,
  transformAccounts,
} from '../../utils/Utils';
import SearchForm from '../../partials/actions/SearchForm';
import { Cross } from './Svg';
import { Loader } from '../../components/Svg';
import AddProjectModal from './AddProjectModal';
import {
  assignInvoiceToBill,
  createProject,
  getAccounts,
  getAllProjects,
  getCurrenciesData,
  getCustomers,
  getInvoices,
  getPayableBills,
  getPeople,
  getReceivableInvoices,
  updateProject,
} from '../../API/backend_helper';
import AddCustomerModal from '../invoices/AddCustomerModal';
import moment from 'moment';
import emptyCustomerImg from '../../images/custom/empty_customer.svg';
// import ProjectView from "./ProjectView";
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import InvoiceForm from '../invoices/InvoiceForm';
import Axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  INVOICES_TAB,
  EDIT_INVOICE,
  CREATE_INVOICE,
  ESTIMATES_TAB,
} from '../invoices';
import InvoiceTransactionsModal from '../invoices/TransactionsModal';
import { toast } from 'react-toastify';
import CalendarView from './CalendarView';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AppLayout from '../../components/AppLayout';
import { useSelector } from 'react-redux';

let searchTimeout = null;
export const statusOptions = [
  {
    name: 'Not Started',
    textColor: '#667085',
    background: '#6670851A',
    id: 'Not started',
  },
  {
    name: 'In Progress',
    textColor: '#42B4F7',
    background: '#42B4F71A',
    id: 'In progress',
  },
  {
    name: 'Completed',
    textColor: '#78BD4F',
    background: '#40AA001A',
    id: 'Completed',
  },
  {
    name: 'Cancelled',
    textColor: '#E48642',
    background: '#E486421A',
    id: 'Cancelled',
  },
];
const Projects = () => {
  const navigate = useNavigate();
  const { accessType } = useSelector((state) => state.User);
  const { currencies } = useSelector((state) => state.Currencies);
  const { team } = useSelector((state) => state.Team);
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [searchVal, setSearchVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('Not started');
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectView, setProjectView] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [finishedFiltering, setFinishedFiltering] = useState(false);
  const [toggledView, setToggledView] = useState('kanban');

  // Customers
  const [customers, setCustomers] = useState([]);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [isActive, setIsActive] = useState(false);
  const [salesRep, setSalesRep] = useState([]);

  document.title = 'COUNT | Projects';

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!createModalOpen) {
      setSelectedCustomer(null);
      setSelectedStatus('Not started');
    }
  }, [createModalOpen]);

  const getSalesRepApi = async () => {
    try {
      const res = await getPeople({
        limit: 1000000,
        roles: ['sales rep'],
      });
      setSalesRep(res?.people);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadData = async () => {
    setIsLoading(true);
    getSalesRepApi();
    await loadCustomers();
    await loadAllProjects({});
    setIsLoading(false);
  };

  const loadCustomers = async () => {
    try {
      const res = await getCustomers({});
      setCustomers(res?.data?.records);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateProject = async (values) => {
    try {
      setModalLoading(true);

      const { startDate, endDate, ...rest } = values;
      const data = { ...rest };

      if (startDate) {
        data.startDate = getFormattedDate(startDate, teamDateFormat);
      } else {
        data.startDate = null;
      }

      if (endDate) {
        data.endDate = getFormattedDate(endDate, teamDateFormat);
      } else {
        data.endDate = null;
      }
      await createProject(data);
      await loadAllProjects({});
      setModalLoading(false);
      toast.success('Project created successfully');
      setCreateModalOpen(false);
    } catch (error) {
      console.error(error);
      setModalLoading(false);
    }
  };

  const loadAllProjects = async ({
    search = '',
    customers = selectedCustomers,
    filter = false,
  }) => {
    try {
      const res = await getAllProjects({
        search: search,
        customers: customers,
      });
      setProjects(res);
      if (selectedProject) {
        setSelectedProject(
          res.find((p) => p.id === selectedProject?.id) || null,
        );
      }
      if (filter) {
        setFinishedFiltering(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSearch = (val) => {
    setSearchVal(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      loadAllProjects({
        search: encodeURIComponent(val || ''),
      });
    }, 500);
  };

  // Drag and Drop Function
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    updateProject(draggableId, { status: destination.droppableId });

    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === parseFloat(draggableId)
          ? { ...project, status: destination.droppableId }
          : project,
      ),
    );
  };

  return (
    <>
      <AddProjectModal
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
        customers={customers}
        setCustomers={setCustomers}
        customerModalOpen={customerModalOpen}
        setCustomerModalOpen={setCustomerModalOpen}
        handleSubmit={handleCreateProject}
        selectedCustomer={selectedCustomer}
        modalLoading={modalLoading}
        selectedStatus={selectedStatus}
        statusOptions={statusOptions}
        project={selectedProject}
      />
      <AddCustomerModal
        isOpen={customerModalOpen}
        setIsOpen={setCustomerModalOpen}
        setSelectedCustomer={(newCustomer) => {
          setCustomers((prev) => [newCustomer, ...prev]);
          setSelectedCustomer(newCustomer);
        }}
        salesRep={salesRep}
      />
      <AppLayout>
        <main className="relative grow ">
          {/* Content */}
          {isActive !== EDIT_INVOICE && (
            <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
              {!selectedProject && !projectView ? (
                <>
                  {/* Page header */}
                  <div
                    className={`flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between mb-10`}
                  >
                    <div className="flex flex-col gap-1">
                      <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                        Projects
                      </h1>
                      <span className="text-lg font-medium">
                        <span
                          className={`${
                            toggledView === 'kanban'
                              ? 'text-black'
                              : 'text-indigo-500 cursor-pointer'
                          }`}
                          onClick={() => {
                            setToggledView('kanban');
                          }}
                        >
                          Project Board
                        </span>{' '}
                        or{' '}
                        <span
                          className={`${
                            toggledView === 'calendar'
                              ? 'text-black'
                              : 'text-indigo-500 cursor-pointer'
                          }`}
                          onClick={() => {
                            setToggledView('calendar');
                          }}
                        >
                          Calendar View
                        </span>
                      </span>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 items-start sm:items-center">
                      <BasicCheckboxDropdown
                        selectedOptions={selectedCustomers}
                        setSelectedOptions={setSelectedCustomers}
                        apiFunc={(obj) =>
                          loadAllProjects({ ...obj, filter: true })
                        }
                        options={
                          customers?.map((c) => ({
                            label: c?.customer
                              ? c?.customer
                              : c?.contacts?.[0]?.firstName,
                            value: `${c?.id}`,
                          })) || []
                        }
                        onApply={(values) => {
                          loadAllProjects({
                            customers: values,
                            filter: true,
                          });
                        }}
                        onClear={() => {
                          loadAllProjects({ customers: [] });
                          setSelectedCustomers([]);
                          setFinishedFiltering(false);
                        }}
                        label="All Customers"
                        type="customers"
                        search
                        apiFilter={
                          finishedFiltering
                            ? selectedCustomers
                            : [...selectedCustomers, 'blank']
                        }
                        width="w-[300px]"
                      />
                      <SearchForm
                        placeholder="Search"
                        searchVal={searchVal}
                        setSearchVal={onSearch}
                        transaction
                      />
                      {accessType !== VIEWER && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setCreateModalOpen(true);
                          }}
                          className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                        >
                          <Cross />
                          Add a Project
                        </button>
                      )}
                    </div>
                  </div>
                  {isLoading && (
                    <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                      <Loader />
                      <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                        Fetching data, please wait...
                      </p>
                    </div>
                  )}
                  {!isLoading &&
                    (toggledView === 'kanban' ? (
                      <DragDropContext onDragEnd={onDragEnd}>
                        <div className="w-full flex gap-6">
                          {statusOptions.map((status) => (
                            <div
                              className="w-[20%] flex flex-col"
                              key={status.id}
                            >
                              {/* Project Header */}
                              <div className="flex items-center gap-2 mb-6">
                                <div
                                  style={{
                                    backgroundColor: status.background,
                                    color: status.textColor,
                                  }}
                                  className={`w-[120px] font-medium bg-opacity-10 rounded-[10px] whitespace-nowrap flex items-center justify-start pl-2 px-8 py-1`}
                                >
                                  {status?.name}
                                </div>
                                <p className="text-slate-600 font-medium">
                                  {
                                    projects?.filter(
                                      (p) => p.status === status?.id,
                                    )?.length
                                  }
                                </p>
                              </div>
                              <Droppable droppableId={status.id}>
                                {(provided) => (
                                  <div
                                    className="flex flex-col gap-4 overflow-auto"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    {projects
                                      ?.filter((p) => p.status === status?.id)
                                      .map((project, index) => (
                                        <Draggable
                                          draggableId={project.id.toString()}
                                          index={index}
                                          key={project.id}
                                        >
                                          {(provided) => (
                                            <div
                                              className="w-full border border-[#D0D5DD] rounded-[10px] px-3 py-4 flex flex-col gap-3 cursor-pointer"
                                              onClick={() => {
                                                navigate(
                                                  `/projects/${project?.id}?tab=overview`,
                                                );
                                              }}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <p className="text-slate-600 font-medium">
                                                {project.name}
                                              </p>
                                              <div className="flex items-center justify-between w-full">
                                                <p className="text-slate-600 text-[14px]">
                                                  {project.customer?.customer}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                    {provided.placeholder}
                                    {/* Add Project */}
                                    <div
                                      className="w-full flex items-center gap-1 cursor-pointer"
                                      onClick={() => {
                                        setSelectedStatus(status.id);
                                        setCreateModalOpen(true);
                                      }}
                                    >
                                      <Cross btn={false} />
                                      <p className="text-slate-400 text-[13px] font-medium">
                                        New Project
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          ))}
                        </div>
                      </DragDropContext>
                    ) : (
                      <>
                        <CalendarView
                          projects={projects}
                          setProjectView={setProjectView}
                          setSelectedProject={setSelectedProject}
                          statusOptions={statusOptions}
                        />
                      </>
                    ))}
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </main>
      </AppLayout>
    </>
  );
};

export default Projects;
