import ModalBasic from 'components/ModalBasic';
import React, { useState } from 'react';

const DeleteUserModal = ({ isOpen, setIsOpen, handleDone, activeUser }) => {
  const [loading, setLoading] = useState(false);

  return (
    <ModalBasic
      title="Delete User"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
    >
      <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
        <p className="text-base leading-6 text-slate-600 text-opacity-80">
          Are you sure you want to delete user{' '}
          {`${activeUser?.firstName} ${activeUser?.lastName}`}? This action
          cannot be undone.
        </p>
      </div>
      <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
        <button
          className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
          type="button"
        >
          No
        </button>
        <button
          className="w-[5.625rem] h-11 
          disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed 
          flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
          onClick={async () => {
            setLoading(true);
            try {
              await handleDone();
            } catch (err) {
              console.log(err);
            } finally {
              setLoading(false);
              setIsOpen(false);
            }
          }}
          disabled={loading}
          type="button"
        >
          {loading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Yes
        </button>
      </div>
    </ModalBasic>
  );
};

export default DeleteUserModal;
