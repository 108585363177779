export const SIGN_UP = '/auth/register';
export const CHECK_EMAIL = '/auth/check-email';
export const ACCOUNTING_FIRM = '/accounting-firm';
export const BANK_STATEMENT = '/api/processPdf';
export const SIGN_IN = '/auth/login';
export const FORGOT_PASSWORD = '/auth/forgot-password';
export const FORGOT_PASSWORD_PERSON = '/auth/forgot-password-people';
export const RESET_PASSWORD = '/auth/reset-password/';
export const RESET_PASSWORD_PERSON = '/auth/reset-people-password/';
export const VERIFY_EMAIL = '/auth/verify-email';
export const VERIFY_OTP = '/auth/verify-otp';
export const RESEND_VERIFY_EMAIL = '/auth/send-otp';
export const CHANGE_PASSWORD = '/auth/change-password';
export const CHANGE_PASSWORD_PERSON = '/auth/change-people-password';

export const PEOPLE_SIGN_IN = '/auth/people-login';

export const GET_CURRENCIES = '/settings/currencies';
export const GET_COUNTRIES = '/settings/countries';
export const GET_STRUCTURES = '/settings/business-structures';

export const GET_USER = '/users/me';
export const PROMO_CODE_VALIDITY = '/promo-codes/verify';
export const USER_SETTINGS = '/settings';
export const GET_POPULAR_BANKS = '/institutions/popular';
export const GET_CONNECTIONS = '/connections';
export const RESOLVED_ALERTS = '/resolve-alerts';
export const CREATE_PLAID_TOKEN = '/providers/plaid/link/token/create';
export const EXCHANGE_PLAID_TOKEN = '/providers/plaid/public-token/exchange';
export const PLAID_SYNC_START = '/providers/plaid/sync/start';
export const AKAHU = '/providers/akahu';
export const GET_ACCOUNT_TYPES = '/account-types';
export const GET_ACCOUNT_SUB_TYPES = '/account-sub-types';
export const GET_ACCOUNTS = '/accounts';
export const GET_ACCOUNT_SYSTEM_BALANCE = '/system-balance';
export const ACCOUNTS_IMPORT_PARSE = '/accounts/import/parse';
export const ACCOUNTS_IMPORT_MAPPING = '/accounts/import/mapping';
export const ACCOUNTS_IMPORT_BULK = '/accounts/bulk';
export const RECONCILE_ACCOUNT = '/reconcile';
export const ACCOUNT = '/account';
export const ACCOUNTS = '/accounts';
export const RECONCILE_OPENING_BALANCE = '/opening-balance';
export const AUTO_RECONCILE = '/auto-reconcile';
export const RECONCILE_COMPLETE = '/complete';
export const RECONCILE_SAVE = '/save';
export const ACCOUNT_TRANS_DETAILS = '/transaction-reviewed-counts';

export const GET_TRANSACTIONS = '/transactions';
export const BULK_EXCLUDE = '/exclude-bulk';
export const BULK_UPDATE_REVIEW_TRANS = '/transactions/update-review';
export const CHANGE_CATEGORY = '/change-category';
export const CHANGE_CATEGORY_BULK = '/change-category-bulk';
export const GET_TRANSACTIONS_MONTHLY_ANALYTICS =
  '/analytics/transactions/sum-by-month';
export const GET_TRANSACTIONS_MENTIONED_COMMENTS =
  '/transactions/mentioned-comments ';

export const GET_CATEGORIES = '/categories';
export const GET_CATEGORIES_GROUPS = '/categories/groups';
export const ACCOUNT_CONNECTIONS = '/connections';
export const UPDATE_LINK_TOKEN = '/update-link-token/';
export const AKAHU_REVOKE_TOKKEN = '/revoke-akahu-access-token';
export const GET_TAGS = '/tags';
export const TAGS_GROUP = '/merge-tags';
export const PRODUCTS = '/products-services';
export const TAXES = '/taxes';
export const MERCHANTS = '/vendors';
export const MERCHANT = '/vendor';
export const AI = '/ai';
export const INSIGHTS = '/insights';
export const SPENDING = '/spending';
export const INCOME = '/income';

export const DASHBOARD = '/analytics/transactions-summery-by-catagory';

export const MEMBERS = '/teams';
export const LAUNCHPAD = '/launchpad-status';
export const REMOVE_USER = '/remove-user';
export const ACCEPT_INVITE = '/accept-invite';
export const DECLINE_INVITE = '/decline-invite';
export const INVITE = '/invite';
export const UPDATE_USER_ROLE = '/update-user-role';
export const LEAVE_TEAM = '/leave';
export const BILLS = '/bills';
export const BILL_EVENTS = '/webhooks/bill-events';
export const GET_PROCESSED_BILL_STAT = '/webhooks/process-bill-sats';
export const PAYABLE = '/payable';
export const BILL_SPLIT = '/split';

export const AVATAR = '/avatar';

export const LOGO = '/logo';

export const RECEIPTS = '/receipts';
export const PENDING_RECEIPT = '/pending-receipt';

export const COMMENTS = '/comments';

export const CUSTOMERS = '/customers';
export const CUSTOMER = '/customer';

export const PEOPLE = '/people';

export const WEB_HOOKS = '/webhooks';
export const TEAM_LOGS = '/logs/enrichs';

export const INSTITUTIONS = '/institutions';

export const DEFAULT_ACCOUNTS = '/internal-accounts';

export const SUBSCRIPTION = '/subscriptions';

export const HISTORY = '/billing-histories';

export const STRIPE_INTENT = '/checkout-session';

export const STRIPE_SUBSCRIPTION = '/set-method-subscribe';

export const GENERATE_REPORTS = '/reports/pnl';
export const TIME_SHEET_REPORT = '/reports/time-sheet';

export const BALANCE_SHEETS = '/reports/balance-sheet';

export const CUSTOMER_INCOME = '/reports/income-by-customer';

export const AGED_RECEIVABLES = '/reports/aged-receivables';

export const CATEGORY_BREAKDOWN = '/reports/category-breakdown';

export const FAVORITE_REPORTS = 'favorite-report';

export const REPORT_HISTORY = '/reports/histories';

export const GENERATE_ACCOUNT_TRANSACTION = '/reports/account-transactions';
export const GENERATE_SALES_TAX_REPORT = '/reports/sales-tax';
export const GENERATE_TAXABLE_NON_TAXABLE_INCOME_REPORT =
  '/reports/taxable-non-taxable';

export const GENERATE_INVOICES_BY_SALES_REP = '/reports/sales-rep-report';

export const EXPENSE_REPORTER_REPORT = '/reports/expense-reporter';

export const JOURNAL_ENTRIES = '/journal-entries';

export const INVOICES = '/invoices';
export const PUBLIC = '/public';
export const RECEIVABLE = '/receivable-balances';

export const CUSTOMIZE = '/customize';

export const STRIPE_CONNECT_LINK = '/generate-stripe-connect-link';
export const STRIPE_LOGIN_LINK = '/stripe-login-link';
export const STRIPE_ACCOUNT_DETAILS = '/stripe-connected-account';

export const ENRICH_PLAYGROUND = '/ai/enrich-playground';

export const INTERNAL_VENDORS = '/internal-vendors';

export const INBOX = '/inbox';

export const TASK = '/task';

export const NOTIFICATION = '/notification';

export const MARK_SEEN = '/mark-seen';

export const CREATE = '/create';

export const AUTOMATIONS = '/automations';
export const AUTOMATION_TEMPLATES = '/automation-templates';

export const AUDIT_LOGS = '/audit-logs';

export const MATCHING_TRANSACTIONS = '/matching-transactions';

export const EXPENSE_REPORTING = '/expense-report';
export const EXPENSE_REPORTERS = '/expense-reporters';
export const ADD_EXPENSE_REPORTER = '/add-accounts';
export const REMOVE_EXPENSE_REPORTER = '/remove-expense-reporter';
export const TIME_ENTRIES = '/time-entries';
export const PROJECT_SUMMARY = '/project-summary';
export const PROJECTS = '/projects';
export const PROJECT_STAT = '/stats';
export const IMPORT = '/import';
export const VENDOR_IMPORT = '/import-from-file';
export const VENDOR_PARSE = '/import-parse-file';
export const CUSTOMER_IMPORT = '/import-from-file';
export const CUSTOMER_PARSE = '/import-parse-file';
export const PRODUCTS_IMPORT = '/smart-field-matching';
export const PRODUCTS_IMPORT_BULK = '/bulk-create';
export const PRODUCTS_PARSE = '/import-parse-file';
export const PARSE = '/parse';
export const FILES = '/files';
export const ATTACHMENTS = '/attachments';

export const APP_INTEGRATION = '/app-integrations';
export const PAYROLLS = '/payrolls';
export const QUICK_BOOK_AUTH = '/quickbooks/auth';
export const QUICK_BOOK_STATUS = '/quickbooks/status';
export const QUICK_BOOK_CUSTOMERS = '/quickbooks/import-customers';
export const QUICK_BOOK_ACCOUNTS = '/quickbooks/import-accounts';
export const QUICK_BOOK_TRANSACTIONS = '/quickbooks/import-transactions';
export const QUICK_BOOK_VENDORS = '/quickbooks/import-vendors';
export const QUICK_BOOK_PRODUCTS = '/quickbooks/import-products';

export const SUBSCRIPTIONS = '/subscriptions';
export const ACTIVE_SUBSCRIPTION = '/subscriptions/active-subscription';
export const SUBSCRIPTION_CHECKOUT = '/subscriptions/checkout-session';
export const SUBSCRIPTION_PAYMENT_METHOD =
  '/subscriptions/setup-intent-session';

export const TEAM_STATS = '/team-income-expense-connection-stat';

export const PERMISSIONS = '/permissions';
export const RELEASE_HISTORY = '/release-history';
