// noinspection ConstantConditionalExpressionJS

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setTeam } from '../../../redux/Team/reducer';
import {
  acceptTeamInviteApi,
  declineTeamInviteApi,
  deleteInvitedMemberApi,
  deleteTeamApi,
  getUser,
  leaveTeamApi,
} from '../../../API/backend_helper';
import LeaveModal from './LeaveModal';
import {
  OWNER,
  STAFF,
  SUPPORT,
  TEAM_CURRENCY,
  TEAM_DATE_FORMAT,
  TEAM_ID,
  TEAM_NAME,
} from '../../../utils/Utils';
import TeamsTable from './TeamsTable';
import InvitesTable from '../invites/InvitesTable';
import AdminInstitution from '../institutions';
import DefaultAccounts from '../defaultAccounts';
import InternalVendors from '../internalVendors';
import { setAccessType, setLocalUser } from '../../../redux/User/reducer';
import { setSubscription } from '../../../redux/Subscription/reducer';
import AccountingFirmsTab from '../accountingFirmsTab';

const Workspaces = ({
  activeVendor,
  setActiveVendor,
  flyoutOpen,
  setFlyoutOpen,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [TeamId, setTeamId] = useState('');
  const TEAMId = localStorage.getItem(TEAM_ID);

  useEffect(() => {
    if (TEAMId) {
      setTeamId(parseInt(TEAMId));
    }
  }, [TEAMId]);

  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [leaveTeam, setLeaveTeam] = useState(null);
  const [deleteWorkspace, setDeleteWorkspace] = useState(null);
  const { localUser } = useSelector((state) => state.User);

  const [activeTab, setActiveTab] = useState(1);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    if (id) {
      setActiveTab(+id);
    }
  }, [id]);

  useEffect(() => {
    if (localUser) {
      setUser(localUser);
    }
  }, [localUser]);

  const getUserApi = async (selectTeam) => {
    try {
      const response = await getUser();
      dispatch(setLocalUser(response));
      setUser(response);

      if (selectTeam && response?.teams?.length > 0) {
        if (response?.realm === STAFF) {
          dispatch(setAccessType(OWNER));
        } else {
          dispatch(
            setAccessType(response?.teams?.[0]?.credentials?.roles?.[0]),
          );
        }
        localStorage.setItem(TEAM_ID, response?.teams?.[0]?.id);
        localStorage.setItem(TEAM_NAME, response?.teams?.[0]?.name);
        setTeamId(response?.teams?.[0]?.id);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    getUserApi();
  }, []);

  const onAcceptInvite = async (id, name) => {
    try {
      const res = await acceptTeamInviteApi(id);
      if (!TEAMId) {
        localStorage.setItem(TEAM_ID, id);
        localStorage.setItem(TEAM_NAME, name);
      }
      await getUserApi();
    } catch (err) {
      console.log('err', err);
    }
  };
  const onDeclineInvite = async (teamId) => {
    try {
      const res = await declineTeamInviteApi(teamId);
      await getUserApi();
    } catch (err) {
      console.log('err', err);
    }
  };

  const onChangeInvite = async (value, team) => {
    if (value === 'accept') {
      await onAcceptInvite(team?.teamId, team?.team?.name);
    }
    if (value === 'decline') {
      await onDeclineInvite(team?.teamId, team?.team?.name);
    }
  };

  const onTeamLeave = async () => {
    setLoading(true);
    try {
      const { value, team, index } = leaveTeam;
      const res = await leaveTeamApi(team?.id);
      if (index !== localUser?.teams?.length - 1) {
        if (team?.id === TeamId) {
          const teamOwner = localUser?.teams?.find((t) => t?.role === OWNER);
          if (teamOwner?.id) {
            if (user?.realm === STAFF || user?.realm === SUPPORT) {
              dispatch(setAccessType(OWNER));
            } else {
              dispatch(setAccessType(teamOwner?.credentials?.roles?.[0]));
            }
            localStorage.setItem(TEAM_ID, teamOwner?.id);
            localStorage.setItem(TEAM_NAME, teamOwner?.name);
            setTeamId(teamOwner?.id);
          }
        } else {
          localStorage.setItem(TEAM_ID, '');
          localStorage.setItem(TEAM_NAME, '');

          setTeamId('');
        }
      } else {
        localStorage.setItem(TEAM_ID, '');
        localStorage.setItem(TEAM_NAME, '');
        setTeamId('');
      }
      await getUserApi(true);
      setIsOpenModal(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsOpenModal(false);
      console.log('err', err);
    }
  };

  const onTeamChange = (id, teamObj = null) => {
    if (id) {
      dispatch(setTeam({}));
      dispatch(setSubscription(''));
      localStorage.setItem(TEAM_ID, id);
      localStorage.setItem(TEAM_NAME, teamObj?.name);
      localStorage.setItem(TEAM_CURRENCY, teamObj?.currency);
      localStorage.setItem(TEAM_DATE_FORMAT, teamObj?.defaults?.dateFormat);
      setTeamId(id);
      if (user?.realm === STAFF || user?.realm === SUPPORT) {
        dispatch(setAccessType(OWNER));
      }
      navigate('/');
      // window.location.reload();
    }
  };

  const onDeleteWorkspace = async () => {
    setLoading(true);
    try {
      const res = await deleteTeamApi(deleteWorkspace?.id);
      localStorage.setItem(TEAM_ID, '');
      localStorage.setItem(TEAM_NAME, '');
      setTeamId('');
      await getUserApi(true);
      setDeleteWorkspace(null);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setDeleteWorkspace(false);
      console.log('err', err);
    }
  };

  return (
    <>
      <LeaveModal
        isOpen={isOpenModal}
        title="Leave Workspace"
        description="Are you sure you want to leave selected worksapce(s) ? This action cannot be undone."
        setIsOpen={setIsOpenModal}
        onCompletion={onTeamLeave}
        loading={loading}
        team={leaveTeam}
      />
      <LeaveModal
        isOpen={deleteWorkspace}
        title="Delete Workspace"
        description={`Are you sure you want to delete "${deleteWorkspace?.name}"? This action cannot be undone.`}
        setIsOpen={setDeleteWorkspace}
        onCompletion={onDeleteWorkspace}
        loading={loading}
        team={deleteWorkspace}
      />
      <div className="flex overflow-hidden" style={{ width: '100%' }}>
        <div className="relative flex  flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-[#182235]">
          {false ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <main className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <h1 className="mb-[30px] text-2xl md:text-[36px] text-[#00000099] dark:text-slate-100 font-medium ">
                {user?.realm === SUPPORT
                  ? 'Staff Support'
                  : user?.realm === STAFF
                    ? 'Staff Account'
                    : 'Switch Workspace'}
              </h1>

              <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
                <div
                  onClick={() => setActiveTab(1)}
                  className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === 1 &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                >
                  Manage Workspaces
                </div>
                <div
                  onClick={() => setActiveTab(2)}
                  className={`text-sm px-4 border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === 2 &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                >
                  {user?.realm === STAFF || user?.realm === SUPPORT
                    ? 'Manage Institutions'
                    : 'My Invites'}
                </div>
                {(user?.realm === STAFF || user?.realm === SUPPORT) && (
                  <div
                    onClick={() => setActiveTab(3)}
                    className={`text-sm px-4 border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === 3 &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                  >
                    Default Accounts
                  </div>
                )}
                {(user?.realm === STAFF || user?.realm === SUPPORT) && (
                  <div
                    onClick={() => setActiveTab(4)}
                    className={`text-sm px-4 border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === 4 &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                  >
                    Internal Vendors
                  </div>
                )}

                {(user?.realm === STAFF || user?.realm === SUPPORT) && (
                  <div
                    onClick={() => setActiveTab(5)}
                    className={`text-sm px-4 border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === 5 &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                  >
                    Accounting Firms
                  </div>
                )}
              </div>
              {activeTab === 1 && (
                <TeamsTable
                  TeamId={TeamId}
                  setLeaveTeam={setLeaveTeam}
                  setDeleteWorkspace={setDeleteWorkspace}
                  setIsOpenModal={setIsOpenModal}
                  selectedTeams={selectedTeams}
                  setSelectedTeams={setSelectedTeams}
                  user={user}
                  onTeamChange={onTeamChange}
                />
              )}
              {activeTab === 2 &&
                (user?.realm === STAFF || user?.realm === SUPPORT ? (
                  <AdminInstitution />
                ) : (
                  <InvitesTable onChangeInvite={onChangeInvite} />
                ))}
              {activeTab === 3 &&
                (user?.realm === STAFF || user?.realm === SUPPORT) && (
                  <DefaultAccounts />
                )}
              {activeTab === 4 &&
                (user?.realm === STAFF || user?.realm === SUPPORT) && (
                  <InternalVendors
                    activeVendor={activeVendor}
                    setActiveVendor={setActiveVendor}
                    flyoutOpen={flyoutOpen}
                    setFlyoutOpen={setFlyoutOpen}
                  />
                )}
              {activeTab === 5 &&
                (user?.realm === STAFF || user?.realm === SUPPORT) && (
                  <AccountingFirmsTab />
                )}
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default Workspaces;
